<template>
  <v-row v-if="tarifa.action !== 'delete'">
    <v-col
      cols="12"
      md="4"
      offset-md="1"
    >
      {{ tarifa.code }}
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-select
        v-model="tarifa.id"
        :disabled="$store.state.app.onlyShow || tarifa.action === 'delete'"
        :items="tarifasHotel"
        :label="$t('lbl.tarifa')"
        outlined
        dense
        clearable
        hide-details
        item-text="name"
        item-value="code"
        @change="setTarifa"
        @click:clear.prevent="clearSetTarifa"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.tarifa') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>{{ `${item.name} (${item.code})` }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-select>

      <!--<v-select
        v-model="tarifa.category_id"
        :disabled="$store.state.app.onlyShow || tarifa.action === 'delete'"
        :items="categoriesTarifarios"
        :label="$t('menu.categoriesTarifarios')"
        outlined
        dense
        clearable
        hide-details
        item-text="name"
        item-value="id"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('menu.categoriesTarifarios') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>-->
    </v-col>
    <v-col
      cols="12"
      md="2"
    >
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            :disabled="tarifa ? ((tarifa.id || tarifa.action === 'add') ? true : false) : false"
            v-on="on"
            @click="addModal"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>
      <!--<v-tooltip
        v-if="tarifa.action !== 'delete'"
        top
        color="error"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="error"
            outlined
            @click="deleteModal()"
            v-on="on"
          >
            <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>-->
    </v-col>

    <!-- ADD CHANGE NOM-->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="580px"
    >
      <v-card max-height="600px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addChange', { n: 'tarifa' }) }}
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="tarifa.name"
                :label="$t('lbl.tarifa')"
                outlined
                dense
                hide-details
                @input="searchTarifas"
              >
              </v-text-field>
            </v-col>

            <template v-if="listTarifas.length > 0">
              <v-col
                cols="12"
                md="12"
              >
                <v-divider class="mb-2"></v-divider>
                {{ $t('msg.existTarifas') }}
                <v-divider class="mb-2"></v-divider>

                <ul>
                  <li
                    v-for="(listTar, indHL) in listTarifas"
                    :key="indHL"
                    class="mb-2"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="cursor-pointer"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectTarifa(listTar)"
                        >
                          {{ listTar.name }}<br />
                        </span>
                      </template>
                      <span>
                        {{ $t('btn.asociate') }}
                      </span>
                    </v-tooltip>
                  </li>
                </ul>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            :disabled="!tarifa.name"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DELETE --
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addDelete', { n: tarifa.name }) }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addDelete"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    tarifa: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    categoriesTarifarios: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allTarifas: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      addDelete: false,
      isAddDelete: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
      isAddChange: false,
      listTarifas: [],
      addChange: false,
    }
  },
  computed: {
    ...mapState({
      hotelsChanges: state => state.app.hotelsChanges,
    }),
    tarifasHotel() {
      let result = []
      this.allTarifas.forEach(element => {
        if (this.hotel.id) {
          if (element.hotel_id === this.hotel.id) {
            result = element.tarifas
          }
        } else {
          element.tarifas.forEach(elementTar => {
            let esta = false
            result.forEach(elementRes => {
              if (elementTar.code === elementRes.code) {
                esta = true
              }
            })
            if (esta === false) {
              result.push(elementTar)
            }
          })
        }
      })

      return result
    },
  },

  created() {
    // console.log(this.allTarifas)
  },
  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
    selectTarifa(tarifaItem) {
      this.tarifa.id = tarifaItem.code
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        tarifa_id: this.tarifa.id,
        channel_tarifa_code: this.tarifa.code,
        tarifa_name: this.tarifa.name,
        asociar_tarifa: true,
      }

      this.actionTarifa(json, true)
    },
    setTarifa() {
      if (this.tarifa.id) {
        const json = {
          hotel_id: this.hotel.id,
          contrate_id: this.lote.contrate_id,
          channel_code: this.lote.hotel.code,
          tarifa_id: this.tarifa.id,
          channel_tarifa_code: this.tarifa.code,
          tarifa_name: this.tarifa.name,
        }

        this.actionTarifa(json, false)
      }
    },
    actionTarifa(json, reset) {
      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (reset) {
            this.$emit('getItemHotel')
          }
        })
        .finally(() => {
          this.isAddChange = false
        })
    },
    clearSetTarifa() {
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        tarifa_id: this.tarifa.id,
        channel_tarifa_code: this.tarifa.code,
        clear_tarifa: true,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
    },
    addModal() {
      this.isAddChange = true
      this.searchTarifas()
    },
    confirmAddChange() {
      this.addChange = true
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        tarifa_id: this.tarifa.id,
        channel_tarifa_code: this.tarifa.code,
        tarifa_name: this.tarifa.name,
        action: 'add',
        add_tarifa: true,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.data.success) {
            this.$emit('getItemHotel')
          }
        })
        .finally(() => {
          this.addChange = false
          this.isAddChange = false
        })
    },
    searchTarifas() {
      this.listTarifas = []
      const arrTar = []
      this.allTarifas.forEach(element => {
        element.tarifas.forEach(elementAll => {
          let esta = false
          this.listTarifas.forEach(elementList => {
            if (elementAll.code === elementList.code) {
              esta = true
            }
          })

          if (!esta) {
            arrTar.push(elementAll)
          }
        })
      })

      let rrr = arrTar
      if (this.tarifa.name) {
        rrr = arrTar.filter(e => e.name.toLowerCase().indexOf(this.tarifa.name.toLowerCase()) > -1)
      }

      rrr.forEach(element1 => {
        let esta = false
        this.listTarifas.forEach(element2 => {
          if (element1.code === element2.code) {
            esta = true
          }
        })

        if (!esta) {
          this.listTarifas.push(element1)
        }
      })
    },
    deleteModal() {
      this.isAddDelete = true
    },
    confirmDelete() {
      this.addDelete = true

      const json = {
        tarifas: {
          name: this.tarifa.name,
          code: this.tarifa.code,
          valueCsv: this.tarifa.valueCsv,
          action: 'delete',
        },
      }

      this.tarifa.action = 'delete'

      this.axios
        .post(
          `lotes-contrate-hotetec/add-change/${this.lote.id}`,
          { type: 'tarifas', changes: json },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.$parent.$emit('getLote')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    resetItem() {
      this.addDelete = false
      this.isAddDelete = false
    },
  },
}
</script>
