<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row style="background-color: #f2f2f2">
      <v-col
        cols="12"
        md="4"
      >
        <b>{{ lote.channel | capitalize }}: {{ hotel.name }}</b>
      </v-col>
      <v-col
        v-if="showTab && pos === indexTab"
        cols="12"
        md="4"
      >
        <v-autocomplete
          v-if="hotel.action !== 'add'"
          v-model="hotel.id"
          :items="hotelsList"
          :search-input.sync="hotelSearch"
          hide-selected
          :label="$t('lbl.hotel')"
          outlined
          dense
          item-text="name"
          item-value="id"
          hide-details
          clearable
          single-line
          @click:clear.prevent="clearSetHotel"
          @change="setHotel"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.hotel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="hotel.name"
          :label="$t('lbl.hotel')"
          outlined
          dense
          hide-details
          clearable
          @click:clear="hotel.action = ''"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="showTab && pos === indexTab"
        cols="12"
        md="3"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="mt-1"
              :disabled="hotel ? ((hotel.id || hotel.action === 'add') ? true : false) : false"
              v-on="on"
              @click="addModal"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>

        <!--<v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="ml-5 mt-1"
              :disabled="hotel ? !hotel.id : false"
              v-on="on"
              @click="showRemplace"
            >
              <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.remplace') }}</span>
        </v-tooltip>-->
        <v-tooltip
          top
          color="error"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="error"
              outlined
              class="ml-5 mt-1"
              v-on="on"
              @click="showDelete()"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="ml-5 mt-1"
              :disabled="verifyHotel"
              v-on="on"
              @click="processMap"
            >
              <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('dashboard.channel.processItemMap') }}</span>
        </v-tooltip>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          icon
          @click="showItem()"
        >
          <v-icon>
            {{ showTab && pos === indexTab ? icons.mdiMenuDown : icons.mdiMenuRight }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showTab && pos === indexTab">
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            v-if="hotel.id || hotel.action === 'add'"
            cols="12"
            md="12"
          >
            <FileRoom
              v-for="(room, indR) in hotel.rooms"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :room="room"
              :rooms="allRooms"
              :hotels="listHotel"
              @getItemHotel="getItemHotel"
              @resetTab="resetTab"
            />
          </v-col>
          <v-col
            v-if="hotel.id || hotel.action === 'add'"
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-divider
                  class="mb-1 mt-1"
                ></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="hotel.id || hotel.action === 'add'"
            cols="12"
            md="12"
          >
            <FilePlan
              v-for="(plan, indR) in hotel.planes"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :plan="plan"
              :plans="allPlans"
            />
          </v-col>

          <v-col
            v-if="hotel.id || hotel.action === 'add'"
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-divider
                  class="mb-1 mt-1"
                ></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="hotel.id || hotel.action === 'add'"
            cols="12"
            md="12"
          >
            <FileTarifas
              v-for="(tarifa, indR) in hotel.tarifas"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :tarifa="tarifa"
              :categories-tarifarios="allCategoriesTarifarios"
              :all-tarifas="allTarifas"
              @getItemHotel="getItemHotel"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="pos !== cant - 1">
      <v-col
        cols="12"
        md="12"
      >
        <v-divider
          class="mb-1 mt-1"
        ></v-divider>
      </v-col>
    </v-row>

    <!--DIALOGS-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addDelete', { n: hotel.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="580px"
    >
      <v-card max-height="600px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addChange', { n: 'el hotel' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="hotel.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
                @input="searchHotelsAdd()"
              >
              </v-text-field>
            </v-col>

            <template v-if="listHotelsLoading.length > 0">
              <v-col
                cols="12"
                md="12"
              >
                <v-divider class="mb-2"></v-divider>
                {{ $t('msg.existHotels') }}
                <v-divider class="mb-2"></v-divider>

                <ul>
                  <li
                    v-for="(listHotelsItem, indHL) in listHotelsLoading"
                    :key="indHL"
                    class="mb-2"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="cursor-pointer"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectHotel(listHotelsItem)"
                        >
                          {{ `${listHotelsItem.name} (${listHotelsItem.city}, ${listHotelsItem.country})` }}<br />
                        </span>
                      </template>
                      <span>
                        {{ $t('btn.asociate') }}
                      </span>
                    </v-tooltip>
                  </li>
                </ul>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isAddProcessMap"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('dashboard.channel.processItemMap')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('dashboard.channel.processItemMapMsg', { n: hotel.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddProcessMap = !isAddProcessMap"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmProcessMap()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--<v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addRemplace', { m: hotel.name.name, n: listHotel.filter(e => e.id === hotel.name.id).length > 0 ? listHotel.filter(e => e.id === hotel.name.id)[0].name : '' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="hotel.name.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', { m: hotel.name.name, n: listHotel.filter(e => e.id === hotel.name.id).length > 0
              ? listHotel.filter(e => e.id === hotel.name.id)[0].name
              : ''
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="isAddSave"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.save')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addSave', { n: `el hotel ${hotel.name.name}` }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddSave = !isAddSave"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddSave()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'

import FileRoom from './FileRoom.vue'
import FilePlan from './FilePlan.vue'
import FileTarifas from './FileTarifas.vue'

export default {
  components: {
    FileRoom,
    FilePlan,
    FileTarifas,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    cant: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    listHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotelsPact: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlans: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allCategoriesTarifarios: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allTarifas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    /* hotelsPactUses: {
      type: Array,
    },
     */
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      showRow: false,
      isAddChange: false,
      addChange: false,
      isAddDelete: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddSave: false,
      modelHotel: {
        id: null,
      },
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
      hotelsContrate: [],
      hotelsList: [],
      hotelSearch: null,
      loadingHotels: false,
      listHotelsLoading: [],
      isAddProcessMap: false,
    }
  },
  computed: {
    ...mapState({
      indexTab: state => state.app.indexTab,
      showTab: state => state.app.showTab,
      hotelsChanges: state => state.app.hotelsChanges,
    }),

    hotelsFree() {
      const result = []
      const hotelsContrate = []
      this.listHotel.forEach(element => {
        if (this.hotelsPact.includes(element.id)) {
          hotelsContrate.push(element)
        }
      })

      hotelsContrate.forEach(element => {
        if (element) {
          result.push(element)

          /* let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.hotelsChanges.length; index++) {
            if (this.hotelsChanges[index].name.id === element.id && index !== this.pos) {
              esta = true
            }
          }

          // VERIFICAR QUE YA NO ESTE EN EL CONTRATO
          if (this.hotelsPactUses.includes(element.id)) {
            esta = true
          }

          if (!esta) {
            result.push(element)
          } */
        }
      })

      return result
    },
    verifyHotel() {
      let result = false

      if (!this.hotel.id && this.hotel.action !== 'add') {
        result = true
      } else {
        this.hotel.rooms.forEach(element => {
          if (!element.id && element.action !== 'add') {
            result = true
          }
        })
        this.hotel.planes.forEach(element => {
          if (!element.id) {
            result = true
          }
        })
        this.hotel.tarifas.forEach(element => {
          if (!element.id) {
            result = true
          }
        })
      }

      return result
    },
  },
  watch: {
    hotelSearch(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      } else {
        this.hotelsList = this.hotelsFree
      }
    },
  },

  created() {
    if (this.hotel.id) {
      this.hotelsList = this.hotelsFree.filter(e => e.id === this.hotel.id)
    }

    // this.hotelsList = this.hotelsFree
  },
  methods: {
    ...mapMutations([
      'setShowTab',
      'setIndexTab',
      'updateHotelsChange',
      'setHotelsChange',
    ]),
    filterHotel() {
      this.hotelsList = this.hotelsFree.filter(e => e.name.toLowerCase())
    },
    showItem() {
      this.hotelsList = this.hotelsFree
      if (!this.showTab) {
        this.setShowTab(true)
        this.setIndexTab(this.pos)
      } else if (this.showTab && this.pos === this.indexTab) {
        this.setShowTab(false)
        this.setIndexTab(-1)
      } else if (this.showTab && this.pos !== this.indexTab) {
        this.setIndexTab(this.pos)
      }
    },
    selectHotel(hotelItem) {
      this.hotel.id = hotelItem.id
      // eslint-disable-next-line no-unused-vars
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        channel_name: this.lote.hotel.name,
        asociar_hotel: true,
      }

      this.actionHotel(json, true)
    },
    setHotel() {
      // this.hotelsList = this.hotelsFree
      this.setHotelsChange({ pos: this.pos, item: this.hotel })

      if (this.hotel.id) {
        const json = {
          hotel_id: this.hotel.id,
          contrate_id: this.lote.contrate_id,
          channel_code: this.lote.hotel.code,
          channel_name: this.lote.hotel.name,
        }

        this.actionHotel(json, false)
      }
    },
    actionHotel(json, reset) {
      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (reset) {
            this.$emit('getItem')
            this.resetTab()
            this.hotelsList = this.hotelsFree
          }
        })
        .finally(() => {
          this.isAddChange = false
        })
    },
    resetTab() {
      this.setShowTab(false)
      setTimeout(() => {
        this.setShowTab(true)
      }, 50)
    },
    clearSetHotel() {
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        channel_name: this.lote.hotel.name,
        clear: true,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
        .finally(() => {
          this.hotel.action = ''
        })
    },
    showDelete() {
      this.isAddDelete = true
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        id: this.lote.id,
      }

      this.axios
        .post('channel-solways/delete-json-file', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.data.success) {
            this.$emit('getItem')
          }
        })
        .finally(() => {
          this.isAddDelete = false
          this.addChange = false
        })
    },
    getItemHotel() {
      // this.resetTab()
      this.$emit('getItem')
      this.$emit('getHotels')
    },
    goTarifarios() {
      localStorage.setItem('lote-solways-id', this.lote.id)
      this.$router.push({ name: 'channel-simulator-tarifarios-solways' })
    },
    processMap() {
      this.isAddProcessMap = true
    },
    confirmProcessMap() {
      this.addChange = true
      const json = {
        id: this.lote.id,
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_hotel_code: this.hotel.code,
      }

      this.axios
        .post('channel-solways/process-file', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        .then(response => {
          if (response.data.success) {
            this.$toast.success(this.$t('dashboard.channel.processItemMapSuccess'))
            this.$emit('getItem')
            this.setShowTab(false)
          }
        })
        .finally(() => {
          this.addChange = false
          this.isAddProcessMap = false
        })
    },

    addModal() {
      this.isAddChange = true
      this.searchHotelsAdd()
    },
    searchHotelsAdd() {
      this.loadingHotels = true

      const json = {
        search: this.hotel.name,
      }

      // this.listHotelsLoading = []

      if (this.hotel.name) {
        this.axios
          .post('channel-solways/search-hotels', json,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (response.data.success) {
              this.listHotelsLoading = response.data.data
            }
          })
          .finally(() => {
            this.loadingHotels = false
          })
      } else {
        this.listHotelsLoading = []
      }
    },
    confirmAddChange() {
      this.addChange = true
      const json = {
        action: 'add',
        add_hotel: true,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        channel_name: this.hotel.name,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.data.success) {
            this.$emit('getItem')
            this.isAddChange = false
          } else {
            this.$toast.error(this.$t('msg.existHotel', { n: this.hotel.name }))
          }
        })
        .finally(() => {
          this.addChange = false
        })
    },
    showRemplace() {
      this.isAddRemplace = true
    },

    /* confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.hotel.name.name,
        typeName: null,
        valueCsv: this.hotel.name.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.hotel.name.id,
        type: 'hotel',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.$toast.success(this.$t('msg.infoSuccess'))
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    showAsociate() {
      this.isAddAsociate = true
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.hotel.name.name,
        typeName: null,
        valueCsv: this.hotel.name.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.hotel.name.id,
        type: 'hotel',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.resetItem()
        })
    },
    addSave() {
      this.isAddSave = true
    },
    confirmAddSave() {
      this.addChange = true
      const json = {
        item: this.hotel,
        id: this.lote.id,
        contrate_id: localStorage.getItem('hotels-contrate-id'),
      }

      this.axios
        .post('lotes-contrate-hotetec/process-item', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')

          // this.$emit('setModify')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    resetItem() {
      this.addChange = false
      this.isAddChange = false
      this.isAddDelete = false
      this.isAddRemplace = false
      this.isAddAsociate = false
      this.isAddSave = false
    },
    getL() {
      this.$emit('getLote')
    }, */
  },
}
</script>
