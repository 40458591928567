var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tarifa.action !== 'delete')?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","offset-md":"1"}},[_vm._v(" "+_vm._s(_vm.tarifa.code)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{attrs:{"disabled":_vm.$store.state.app.onlyShow || _vm.tarifa.action === 'delete',"items":_vm.tarifasHotel,"label":_vm.$t('lbl.tarifa'),"outlined":"","dense":"","clearable":"","hide-details":"","item-text":"name","item-value":"code"},on:{"change":_vm.setTarifa,"click:clear":function($event){$event.preventDefault();return _vm.clearSetTarifa.apply(null, arguments)}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('lbl.tarifa'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(((item.name) + " (" + (item.code) + ")")))])],1)]}}],null,false,3442099396),model:{value:(_vm.tarifa.id),callback:function ($$v) {_vm.$set(_vm.tarifa, "id", $$v)},expression:"tarifa.id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","x-small":"","color":"primary","outlined":"","disabled":_vm.tarifa ? ((_vm.tarifa.id || _vm.tarifa.action === 'add') ? true : false) : false},on:{"click":_vm.addModal}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)]}}],null,false,2290260471)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"580px"},model:{value:(_vm.isAddChange),callback:function ($$v) {_vm.isAddChange=$$v},expression:"isAddChange"}},[_c('v-card',{attrs:{"max-height":"600px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('btn.insert')))))]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" "+_vm._s(_vm.$t('msg.addChange', { n: 'tarifa' }))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('lbl.tarifa'),"outlined":"","dense":"","hide-details":""},on:{"input":_vm.searchTarifas},model:{value:(_vm.tarifa.name),callback:function ($$v) {_vm.$set(_vm.tarifa, "name", $$v)},expression:"tarifa.name"}})],1),(_vm.listTarifas.length > 0)?[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-divider',{staticClass:"mb-2"}),_vm._v(" "+_vm._s(_vm.$t('msg.existTarifas'))+" "),_c('v-divider',{staticClass:"mb-2"}),_c('ul',_vm._l((_vm.listTarifas),function(listTar,indHL){return _c('li',{key:indHL,staticClass:"mb-2"},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"cursor-pointer",on:{"click":function($event){return _vm.selectTarifa(listTar)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(listTar.name)),_c('br')])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('btn.asociate'))+" ")])])],1)}),0)],1)]:_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isAddChange = !_vm.isAddChange}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.addChange,"disabled":!_vm.tarifa.name},on:{"click":function($event){return _vm.confirmAddChange()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }