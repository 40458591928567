<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      {{ room.name }}
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-select
        v-if="room.action !== 'add'"
        v-model="room.id"
        :disabled="$store.state.app.onlyShow"
        :items="roomsFree"
        :label="$t('lbl.rooms')"
        outlined
        dense
        clearable
        hide-details
        item-text="name"
        item-value="id"
        @change="setRoom"
        @click:clear.prevent="clearSetRoom"
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('lbl.room') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
      <v-text-field
        v-else
        v-model="room.name"
        :label="$t('lbl.room')"
        outlined
        dense
        hide-details
        clearable
        @click:clear="room.action = ''"
      >
      </v-text-field>
    </v-col>
    <!--<v-col
      v-if="pos < hotel.rooms.length - 1"
      cols="12"
      md="10"
      offset-md="1"
    >
      <v-divider></v-divider>
    </v-col>-->

    <v-col
      cols="12"
      md="2"
    >
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            :disabled="room ? ((room.id || room.action === 'add') ? true : false) : false"
            v-on="on"
            @click="addModal"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.insert') }}</span>
      </v-tooltip>

      <!--<v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            class="ml-5"
            :disabled="!room.room.id"
            v-on="on"
            @click="showRemplace"
          >
            <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.remplace') }}</span>
      </v-tooltip>

      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            x-small
            v-bind="attrs"
            color="primary"
            outlined
            class="ml-5"
            :disabled="!room.room.id"
            v-on="on"
            @click="showAsociate"
          >
            <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.asociate') }}</span>
      </v-tooltip>-->
    </v-col>

    <!-- ADD CHANGE NOM-->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="580px"
    >
      <v-card max-height="600px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addChange', { n: 'la habitación' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="room.name"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
                @input="searchRoomsAdd"
              >
              </v-text-field>
            </v-col>

            <template v-if="listRoomsLoading.length > 0">
              <v-col
                cols="12"
                md="12"
              >
                <v-divider class="mb-2"></v-divider>
                {{ $t('msg.existRooms') }}
                <v-divider class="mb-2"></v-divider>

                <ul>
                  <li
                    v-for="(listRoomItem, indHL) in listRoomsLoading"
                    :key="indHL"
                    class="mb-2"
                  >
                    <v-tooltip
                      top
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="cursor-pointer"
                          v-bind="attrs"
                          v-on="on"
                          @click="selectRoom(listRoomItem)"
                        >
                          {{ listRoomItem.name }}<br />
                        </span>
                      </template>
                      <span>
                        {{ $t('btn.asociate') }}
                      </span>
                    </v-tooltip>
                  </li>
                </ul>
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE--
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addRemplace', { m: room.room.name, n: rooms.filter(e => e.id === room.room.id).length > 0 ? rooms.filter(e => e.id === room.room.id)[0].name : '' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="room.room.name"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    --MODAL ASOCIATE--
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', { m: room.room.name, n: rooms.filter(e => e.id === room.room.id).length > 0
              ? rooms.filter(e => e.id === room.room.id)[0].name
              : ''
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    rooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotels: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      isAddChange: false,
      addChange: false,
      isAddDelete: false,
      isAddRemplace: false,
      isAddAsociate: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
      loadingRooms: false,
      listRoomsLoading: [],
    }
  },
  computed: {
    ...mapState({
      hotelsChanges: state => state.app.hotelsChanges,
    }),
    roomsFree() {
      const result = []
      const roomsHotel = []

      if (this.hotel.id) {
        const hotelito = this.hotels.filter(e => e.id === this.hotel.id)[0]
        this.rooms.forEach(element => {
          if (hotelito.rooms_id.includes(element.id)) {
            roomsHotel.push(element)
          }
        })
      } else {
        this.rooms.forEach(element => {
          roomsHotel.push(element)
        })
      }

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.hotel.rooms.length; index++) {
            if (this.hotel.rooms[index].id === element.id && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
  },

  methods: {
    ...mapMutations([
      'addCupoRoomSeasonContrateHotel',
      'deleteCupoRoomSeasonContrateHotel',
      'replicaCupoRoomSeasonContrateHotel',
      'replicaCupoSeasonContrateHotel',
      'editReleaseByCupoSeasonContrateHotel',
    ]),
    selectRoom(roomItem) {
      this.room.id = roomItem.id
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        room_id: this.room.id,
        channel_room_code: this.room.code,
        channel_room_name: this.room.name,
        asociar_room: true,
      }

      this.actionRoom(json, true)
    },
    setRoom() {
      if (this.room.id) {
        const json = {
          hotel_id: this.hotel.id,
          contrate_id: this.lote.contrate_id,
          channel_code: this.lote.hotel.code,
          room_id: this.room.id,
          channel_room_code: this.room.code,
          channel_room_name: this.room.name,
        }

        this.actionRoom(json, false)
      }
    },
    // eslint-disable-next-line no-unused-vars
    actionRoom(json, reset) {
      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (reset) {
            this.$emit('getItemHotel')
          }
        })
        .finally(() => {
          this.isAddChange = false
        })
    },
    clearSetRoom() {
      const json = {
        hotel_id: this.hotel.id,
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        room_id: this.room.id,
        channel_room_code: this.room.code,
        channel_room_name: this.room.name,
        clear_room: true,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {})
    },
    addModal() {
      this.isAddChange = true
      this.searchRoomsAdd()
    },
    confirmAddChange() {
      this.addChange = true
      const json = {
        contrate_id: this.lote.contrate_id,
        channel_code: this.lote.hotel.code,
        action: 'add',
        add_room: true,
        channel_room_code: this.room.code,
        channel_room_name: this.room.name,
      }

      this.axios
        .post('channel-solways/update-mapeo', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          if (response.data.success) {
            this.$emit('getItemHotel')
          } else {
            this.$toast.error(this.$t('msg.existRoom', { n: this.room.name }))
          }
        })
        .finally(() => {
          this.addChange = false
          this.isAddChange = false
        })
    },
    searchRoomsAdd() {
      this.loadingRooms = true

      const json = {
        search: this.room.name,
      }

      if (this.room.name) {
        this.listRoomsLoading = []
        this.axios
          .post('channel-solways/search-rooms', json,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (response.data.success) {
              const result = response.data.data
              result.forEach(elementRS => {
                let esta = false
                this.hotel.rooms.forEach(elementRH => {
                  if (elementRS.id === elementRH.id) {
                    esta = true
                  }
                })

                if (!esta) {
                  this.listRoomsLoading.push(elementRS)
                }
              })
            }
          })
          .finally(() => {
            this.loadingRooms = false
          })
      } else {
        this.listRoomsLoading = []
      }
    },
    showRemplace() {
      this.isAddRemplace = true
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.room.room.name,
        typeName: null,
        valueCsv: this.room.room.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.room.room.id,
        type: 'room',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.room.room.action = 'remplace'

          // this.$toast.success(this.$t('msg.infoSuccess'))
        })
        .finally(() => {
          this.resetItem()
        })
    },
    showAsociate() {
      this.isAddAsociate = true
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.room.room.name,
        typeName: null,
        valueCsv: this.room.room.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.hotel.name.id,
        type: 'room',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.resetItem()
        })
    },
    resetItem() {
      this.addChange = false
      this.isAddChange = false
      this.isAddDelete = false
      this.isAddRemplace = false
      this.isAddAsociate = false
    },
  },
}
</script>
